<template>
    <div class="main_panel">
        <header>
            <div class="page_title">
                <a class="menutrigger"></a>
                <span class="type_of_user">{{ auth().user_type.split("_").join(" ") }}</span>
            </div>
            <div class="logout-container">
                <span>Welcome <span>{{ user.name }}</span></span>
                <div class="logout" @click="logout"></div>
            </div>
        </header>
        <div class="under_header">
            <BreadCrumb :data="data" />
        </div>
        <div class="main_section">
            <router-view @breadCrumbData="breadCrumbData" />
        </div>
    </div>
</template>

<script>
import BreadCrumb from "./Breadcrumb.vue";

export default {
    name: "MainPanel",
    components: {
        BreadCrumb
    },
    created() {
        this.fetchUser();
    },
    data() {
        return {
            user: {},
            data: []
        }
    },
    methods: {
        breadCrumbData(data) {
            this.data = data;
        },
        fetchUser() {
            this.axios.get("api/user")
                .then(response => {
                    this.user = response.data.user;
                });
        },
        logout() {
            this.axios.post("api/auth/logout")
                .then(response => {
                    localStorage.clear();
                    this.$router.push("/");
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.main_panel {
    width: calc(100% - 300px);
    height: auto;
    background: #fff;
    overflow: auto;
    margin-left: 300px;
}

.main_panel>header {
    height: 65px;
    background: #e3e3e3;
    position: fixed;
    left: 300px;
    width: calc(100% - 300px);
    z-index: 9;
    display: flex;
    justify-content: space-between;
}

.menutrigger {
    width: 40px;
    height: 40px;
    display: block;
    background: url(../assets/menu.png) no-repeat;
    background-size: contain;
    float: left;
    position: relative;
    left: 10px;
    top: 15px;
}

.main_panel header .page_title {
    font-size: 22px;
    height: 65px;
    line-height: 65px;
    float: left;
    color: #333;
}

.main_panel header .page_title a {
    margin-right: 10px;
}

.main_panel header .page_title span {
    display: inline-block;
    padding-left: 10px;
}

.main_panel header .name_title {
    font-size: 22px;
    height: 65px;
    line-height: 65px;
    float: right;
    margin-right: 60px;
    color: #333;
}

.main_panel header .logout-container {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.main_panel header .logout-container span {
    margin-right: 10px;
}

.main_panel header .logout-container span span {
    font-weight: bold;
}

.main_section {
    /* height: auto; */
    height: calc(100vh - 120px);
    margin-top: 120px;
    overflow: auto;
}

.logout {
    width: 100px;
    height: 65px;
    display: block;
    background: #004a7c url(../assets/ico_logout.png) no-repeat;
    background-position: center;
    z-index: 100;
    right: 0;
    top: 0;
    border: 0;
}

.float-right {
    float: right;
}

.type_of_user {
    text-transform: capitalize;
}</style>
